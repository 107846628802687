/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function Seo({ description, lang, meta, title }: {description: string, lang: string, meta: any, title: string}) {
    const { site } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
    );

    const metaDescription = description || site.siteMetadata.description;

    return (
        <Helmet
            htmlAttributes={{
        lang,
    }}
    title={title}
    titleTemplate={`%s | ${site.siteMetadata.title}`}
    meta={[
            {
                name: `description`,
                content: metaDescription,
            },
    {
        property: `og:title`,
            content: title,
    },
    {
        property: `og:description`,
            content: metaDescription,
    },
    {
        property: `og:type`,
            content: `website`,
    },
    {
        name: `twitter:card`,
            content: `summary`,
    },
    {
        name: `twitter:creator`,
            content: site.siteMetadata.author,
    },
    {
        name: `twitter:title`,
            content: title,
    },
    {
        name: `twitter:description`,
            content: metaDescription,
    },
].concat(meta)}
    />
);
}

Seo.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
};

export default Seo;
